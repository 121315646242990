
















































import Vue from "vue";
import _ from 'lodash';
import moment from "moment";
import notificationMixin from '../../mixin/notification';
import store from '../../store/shop';
import ShopAPI from '../../common/api/api_shop';
import {TranslateServiceBuilder} from "../../services/Translate/translateServiceBuilder";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.component('LastOrderTime', {
  components: {
    QLoader: () => import('../../components/elements/QLoader.vue'),
    QButton: () => import("../../components/elements/QButton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mixins: [
    notificationMixin,
  ],
  data() {
    return {
      errors: {},
      last_order_times: [],
      isLoading: false,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    this.last_order_times = _.map(store.state.shopSetting.last_order_times, function (item) {
      let time_array = item.time.split(":");

      item.hour = time_array[0].length < 2 ? '0' + time_array[0] : time_array[0].toString();
      item.minute = time_array[1].length < 2 ? '0' + time_array[1] : time_array[1].toString();
      item.release_time = item.release_time.length < 2 ? '0' + item.release_time : item.release_time.toString();
      return item;
    });
  },
  computed: {
    shoppix() {
      return this.$route.params.shoppix;
    }
  },
  methods: {
    addInput: function () {
      this.last_order_times.push({id: null, time: '', release_time: ''});
    },
    removeInput: function (index) {
      let id = this.last_order_times[index].id;

      if (id) {
        let data = {
          id: id,
          deleted: moment().format('YYYY-MM-DD HH:mm:ss')
        };

        this.callApi(data);
      }

      this.last_order_times.splice(index, 1);
    },
    addUpdateLastOrderTime: function (id, hour, minute, release_time) {
      if (!hour || !minute || !release_time) {
        return;
      }

      let shopId = this.shoppix;
      hour = hour.length < 2 ? '0' + hour : hour.toString();
      minute = minute.length < 2 ? '0' + minute : minute.toString();
      release_time = release_time.length < 2 ? '0' + release_time : release_time.toString();
      let time = hour + ':' + minute;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.callApi({id: id, shop_id: shopId, time: time, release_time: release_time});
      }, 1000);
    },
    callApi: function (data) {
      let message = "";

      api
        .shop_setting_last_order_time(data)
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0046", "保存に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
            return;
          }

          message = response.data.message;
          this.showSuccessNotification(message);
          store.dispatch("setLastOrderTime", _.map(this.last_order_times, function (item) {
              item.time = item.hour + ':' + item.minute;

              return item;
            })
          );
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },
    validateNumber(event, index, key, max) {
      let previous = this.last_order_times[index][key];
      let regex = /^[0-9]+$/;

      if (!event.data || event.data.match(regex)) {
        const value = event.target.value;

        if (value <= max) {
          this.last_order_times[index][key] = value;
        }

        this.$forceUpdate();
      } else {
        this.$refs[key + index][0].value = previous;
      }
    },
  },
});
